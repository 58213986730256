import React, { Component } from 'react'
import './Navbar.css';
import { MenuItems } from './MenuItems';
import Logo from '../../images/logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

    class Navbar extends Component {
        state = { clicked: false };
        handleClick = () => {
          this.setState({ clicked: !this.state.clicked })
        }
        render() {
          return (
            <nav className='navbarItems'>
              <div className='logo'>
                <img className='navLogo' src={Logo} alt=''></img>
              </div>
      
            <div className='menuIcons' onClick={this.handleClick}>
              <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
            </div>
      
              <ul className={this.state.clicked ? 'navMenu active' : 'navMenu' } >
              {MenuItems.map((item, index) => {
                return (
                  <li className={item.cName} key={index}><a href={item.url}>{item.title}</a></li>
                )
              })}
              <a href='/technosearch23'><button>Technosearch'23</button></a>
              </ul>
            </nav>
          )
        }
      }

export default Navbar;