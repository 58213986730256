import React from 'react';
import styles from './Automax.module.css';
import SimpleImageSlider from "react-simple-image-slider";
import Video from '../images/automax.mp4';
import { MdLocationOn, MdPhone, MdOutlineEmail } from "react-icons/md";
import { FaYoutube, FaInstagram, FaFacebookF } from 'react-icons/fa';
import { BiLogoLinkedin } from "react-icons/bi";

const images = [
  { url: "https://images.unsplash.com/photo-1546325688-14d8b23e9f8a?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZGVza3RvcCUyMHdhbGxwYXBlcnxlbnwwfHwwfHx8MA%3D%3D" },
  { url: "https://images.unsplash.com/photo-1627840165499-29463822b02b?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZGVza3RvcCUyMHdhbGxwYXBlcnxlbnwwfHwwfHx8MA%3D%3D" },
  { url: "https://images.unsplash.com/photo-1610730902506-4a6d8d3afbd3?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGRlc2t0b3AlMjB3YWxscGFwZXJ8ZW58MHx8MHx8fDA%3D" },
  { url: "https://images.unsplash.com/photo-1546325688-14d8b23e9f8a?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZGVza3RvcCUyMHdhbGxwYXBlcnxlbnwwfHwwfHx8MA%3D%3D" },
  { url: "https://images.unsplash.com/photo-1627840165499-29463822b02b?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZGVza3RvcCUyMHdhbGxwYXBlcnxlbnwwfHwwfHx8MA%3D%3D" },
  { url: "https://images.unsplash.com/photo-1610730902506-4a6d8d3afbd3?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGRlc2t0b3AlMjB3YWxscGFwZXJ8ZW58MHx8MHx8fDA%3D" },
  
];

const Automax = () => {
  return (
    <>
      
    <div className={styles.automax}>
        <div className={styles.overlay}></div>
            <video src={Video} autoPlay loop muted />

            <div className={styles.container}>
                {/* <h1>ROBOTICS CLUB</h1> */}
                <h1>AUTOMAX 2.0</h1>
                <a href='/'>Back to Home</a>
            </div>
    </div>

    <div className={styles.automaxDescription}>
      <p>" Gear up for the robotic rave of the year! Automax 2.0 is round the corner It's your final call to seize the 
      opportunity to attend this workshop, master the tech essentials, and later compete to prove your prowess in the 
      world of robotics. Time to power up your calendars and let the tech-tide carry you away into a world where 
      innovation knows no bounds. Stay tuned for a maxed-out learning experience that's your gateway to a future where 
      your knowledge becomes power. Don't just witness the change—be the change at AutoMax 2.0! "</p>
      </div>

      <div className={styles.automaxGallery}>
        <div className={styles.heading}>
          <h3>Highlights Of The Event</h3>
          <p>Lorem ipsum dolor sit amet ipsum dolor sit amet</p>
        </div>
        <div className={styles.slider}>
          <SimpleImageSlider
            width={1296}
            height={704}
            images={images}
            showBullets={true}
            showNavs={true}
          />
        </div>

      </div>

      <div className={styles.generalFooter}>
        <div className={styles.footerContent}>
              <div>
                  <a><MdLocationOn /></a>
                  <p>Maulana Azad National Institute<br/> of Technology Bhopal, 462003 (M.P.)</p>
              </div>
              <div>
                  <a><MdPhone /></a>
                  <p>Aryan +91 8770920525 <br />Sakshi +91 9414912292</p>
              </div>
              <div>
                  <a><MdOutlineEmail /></a>
                  <p>roboclubmanit@gmail.com</p>
              </div>
        </div>

              <div className={styles.socials}>
                <a href='https://www.linkedin.com/company/robotics-club-nit-bhopal/mycompany/' target='blank'><BiLogoLinkedin /></a>
                <a href='https://www.instagram.com/roboticsclubmanit/'><FaInstagram /></a>
                <a href='https://www.youtube.com/@roboticsnitb' target='blank'><FaYoutube /></a>
                <a href='https://www.facebook.com/roboticsnitb/' target='blank'><FaFacebookF /></a>
              </div>
              
      </div>
      <div className={styles.footerText}>
                  <p>Copyright &copy; 2023 by <a href='https://linktr.ee/roboticsnitb'>RoboticsNITB</a> | All Rights Reserved.</p>
              </div>
    </>
  )
}

export default Automax