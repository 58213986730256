import './Events.css';
import {motion} from 'framer-motion';
import Autoshow from '../../images/autoshow.png';
import Robofiesta from '../../images/robofiesta.png';
import Automax from '../../images/automax22.png';
import Makeathon from '../../images/makeathon.png'

const Past = () => {
    return (
        <div className="cardContainer">
            <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0 },
            }}
            className='card'>
                <div className='imgBox'>
                    <img src={Automax} alt="" />
                </div>
                <div className='cardContent'>
                    <h3>Automax 1.0</h3>
                    <p>Automax is an exhilarating 2-day robotics workshop and competition that brings together enthusiasts to explore the cutting-edge world of robotics. Join us for a thrilling journey of innovation and competition.</p>
                </div>
            </motion.div>
            <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0 },
            }}
            className='card'>
                <div className='imgBox'>
                    <img src={Autoshow} alt="" />
                </div>
                <div className='cardContent'>
                    <h3>Autoshow</h3>
                    <p>Explore the future of engineering and robotics innovation. Join us for a captivating event where cutting-edge projects and engineering excellence take center stage.</p>
                </div>
            </motion.div>
            <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0 },
            }}
            className='card'>
                <div className='imgBox'>
                    <img src={Robofiesta} alt="" />
                </div>
                <div className='cardContent'>
                    <h3>Robofiesta</h3>
                    <p>Robofiesta is an electrifying event featuring thrilling robot competitions, including robo wrestling, momo game, smash carts, and bristle bot challenges.</p>
                </div>
            </motion.div>
            <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0 },
            }}
            className='card'>
                <div className='imgBox'>
                    <img src={Makeathon} alt="" />
                </div>
                <div className='cardContent'>
                    <h3>Makeathon</h3>
                    <p>A creative playground for innovators! Join teams as they engineer cutting-edge robotics and automated projects, showcasing their ingenuity in this thrilling event</p>
                </div>
            </motion.div>
        </div>
    );
};

export default Past;