import React from "react";
import './Home.css';
import {motion} from 'framer-motion';
import Navbar from "../navbar/Navbar";
import About from "../../components/about/About";
import Gallery from "../../components/gallery/App";
import Achievements from "../../components/achievements/Achievements";
import Team from "../../components/teams/Team";
import Social from "../../components/social/Social";
import Footer from "../../components/footer/Footer";
import Sponsor from "../../components/sponsor/Sponsor";
import Events from "../../components/events/Events";
import Project from "../project/Project";


const Home = () => {
    return (
        <>
        <Social />
        <Navbar />
            <div className="home">
                <div className="homeText">
                    <motion.h1
                        initial={{x: -100}}
                        animate={{x: 0}}
                        transition={{
                            duration: "0.8",
                            delay: "0.3"
                        }}>ROBOTICS CLUB</motion.h1>
                    <motion.h3
                        initial={{x: 100}}
                        animate={{x: 0}}
                        transition={{
                            duration: "0.8",
                            delay: "0.3"
                        }}>MANIT BHOPAL</motion.h3>
                    {/* <h3>MANIT BHOPAL</h3> */}
                </div>
            <a href="#about"><div className="scrollDown"></div></a>
            </div>

            <About />
            <Gallery />
            <Events />
            <Sponsor />
            <Project />
            <Achievements />
            <Team />
            <Footer />
        </>
    )
}

export default Home;