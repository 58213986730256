import React from 'react'
import styles from './Technosearch.module.css';
import Logo from '../images/logo.png';
import Technologo from '../images/technologo.png'
import Techno from '../images/technoseatchtxt.png';
import Video from '../images/technovideo.mp4';

const Technosearch = () => {
  return (
    <>

        <div className={styles.header}>
                <a href='https://roboticsnitb.com/'><img className={styles.logo} src={Logo} alt='' /></a>
                <a href='https://www.instagram.com/technosearch.nitb/'><img className={styles.technoLogo} src={Technologo} alt='' /></a>
            </div>

        <div className={styles.automax}>
        {/* <div className={styles.overlay}></div> */}
            <video src={Video} autoPlay loop preload="auto" />

            <div className={styles.container}>
            <img src={Techno} alt=''/>
                    <div className={styles.homeDetail}>
                        <div><i class='bx bx-calendar-check' ></i><p>November 3rd to 5th</p></div>
                        <div><i class='bx bxs-map'></i><p>MANIT Bhopal</p></div>
                    </div>
            </div>
        </div>

        {/* <div className={styles.technoHome}>
            
            <div className={styles.header}>
                <img className={styles.logo} src={Logo} alt='' />
                <img className={styles.technoLogo} src={Technologo} alt='' />
            </div>

            <div className={styles.homeContainer}>
                <div className={styles.overlay}></div>
                <video src={Video} autoPlay loop muted />
                
                <div className={styles.homeContent}>
                    <img src={Techno} alt=''/>
                    <div className={styles.homeDetail}>
                        <div><i class='bx bx-calendar-check' ></i><p>November 3rd to 5th</p></div>
                        <div><i class='bx bxs-map'></i><p>MANIT Bhopal</p></div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className={styles.events}>
        <div className={styles.borderText}><h1>Compete</h1></div>
            <div className={styles.heading}>
                <h3>#Events</h3>
            </div>
            <div className={styles.bigContainer}>
                <div className={styles.cardContainer}>
                    <div className={styles.card} id={styles.card1}>
                        <div className={styles.cardContent}>
                            <h2>Make-A-Thon</h2>
                            <p>"Rewind, Revive, Reinvent: Make-a-Thon, Your Retrofuturistic Odyssey!"

Step back in time to a future that never was, where our wildest creativity and innovation manifest. At the Make-A-Thon, you can build your own retro-futuristic robot and bring this iconic vision of the future to life.

With kitschy neon lights, gleaming chrome accents, and a dash of mad scientist flair, your robot will be the envy of all. And with prizes for the most creative, innovative, and functional robots, you're sure don't wanna miss out!

So gather your friends, gather your arsenal, and let's build the retrofuturistic robot to traverse beyond the bounds of time! Stay connected for future updates.
</p>
                        </div>
                    </div>
                    <div className={styles.cardBottom}>
                        <div><i class='bx bx-calendar-check' ></i><p>5th of November, 9AM onwards</p></div>
                        <div><i class='bx bxs-map'></i><p>LRC, MANIT</p></div>
                    </div>
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.card} id={styles.card2}>
                        <div className={styles.cardContent}>
                            <h2>Robofiesta</h2>
                            <p>Brace yourself for a shockwave of curiosity as Robofiesta presents you a tech-infused-trio of archery, soccer and sumo bots!

Step into an alternate past, where limitless creativity and innovation shape your destiny. The RoboFiesta invites you to explore the fun of robotics, breathing life into an unrealized vision of the future. Don't miss your chance to showcase your skills in our thrilling archery, soccer, and sumo bots competitions where your creative flows forth into compelling competitive spirit!

Assemble your crew, arm yourselves with an arsenal of tech wizardry, and embark on a journey of robotic extravaganza! Stay connected for future updates.</p>
                        </div>
                    </div>
                    <div className={styles.cardBottom}>
                        <div><i class='bx bx-calendar-check' ></i><p>5th of November, 9AM onwards</p></div>
                        <div><i class='bx bxs-map'></i><p>LRC, MANIT</p></div>
                    </div>
                </div>
            </div>
        </div>


        <div className={styles.register}>
        <div className={styles.borderText}><h1>Register</h1></div>
            <div className={styles.heading}>
                <h3>Register Now</h3>
            </div>

            <div className={styles.formContainer}>
                <div>
                    <input type='text' placeholder='Name' required />
                    <input type='text' placeholder='Institution' required />
                </div>
                <div>
                    <input type='email' placeholder='Email' required />
                    <input type='text' placeholder='City' required />
                </div>
                <div>
                    <input type='number' placeholder='Mobile' required />
                    
                </div>
                <a><button>Register</button></a>
            </div>
        </div>

        <footer>
            <p>Copyright &copy; 2023 by RoboticsNITB</p>
        </footer>
    </>
  )
}

export default Technosearch