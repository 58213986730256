import './App.css';
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import Register from './pages/Automaxregistration';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Automax from './pages/Automax';
import Technosearch from './pages/Technosearch';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/automax' element={<Automax />} />
        <Route path='/technosearch23' element={<Technosearch />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
