import React from 'react';
import styles from './Social.module.css';
import {FaLinkedinIn, FaInstagram, FaYoutube, FaFacebookF} from 'react-icons/fa';

const Social = () => {
  return (
    <div className={styles.social}>
        <a href="https://www.linkedin.com/company/robotics-club-nit-bhopal/mycompany/" target="_blank"><FaLinkedinIn /></a>
        <a href="https://www.instagram.com/roboticsclubmanit/?hl=en" target="_blank"><FaInstagram /></a>
        <a href="https://www.youtube.com/@roboticsnitb" target="_blank"><FaYoutube /></a>
        <a href="https://www.facebook.com/roboticsnitb/" target="_blank"><FaFacebookF /></a>


    </div>
  )
}

export default Social