import React, { Component } from "react";
import Slider from 'react-slick';
import {motion} from 'framer-motion';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Project.css';
import Sponsor from '../sponsor/Sponsor'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Img1 from '../../images/quadcopter 2.jpg';
import Sumobot from '../../images/sumobot.png'
import Smashkart from '../../images/smashkart.png';
import Drone from '../../images/drone.png';
import Roboticarm from '../../images/robotic arm.png';
import Shooter from '../../images/shooter.jpeg';
// import Bristlebot from '../../images/bristlebot.png';

const Project = () => {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
  return (
        <div className="portfolio" id="projects">

            <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
            }}
            className="heading">
                <h3>Our Projects</h3>
                <p>Turning ideas into reality</p>
            </motion.div>

            <Carousel responsive={responsive}>
                <div className="cardContainer">
                        <div className="layer" >
                        <div className="cardImg">
                            <img src={Sumobot} alt="Our Team" />
                        </div>
                        <div className="projectContent">
                            <h5>SUMOBOT</h5>
                            <p className="projectText">"Sumo Bot" is a captivating project designed to showcase the creativity and technical prowess of its creators. It features a thrilling battle between two Bluetooth-controlled robots equipped with two wheels each. These robots engage in an intense showdown within a compact arena, utilizing strategy, agility, and control to outmaneuver their opponent. The objective is simple yet challenging: to force the adversary out of the ring and secure victory. 
</p>
                            <input className="seeMore" type='checkbox' />
                        </div>
                    </div>
                </div>
                <div className="cardContainer">
                        <div className="layer" >
                        <div className="cardImg">
                            <img src={Smashkart} alt="Our Team" />
                        </div>
                        <div className="projectContent">
                            <h5>SMASHKART</h5>
                            <p className="projectText">Smash Karts" is an electrifying project that redefines vehicular combat. This dynamic game features four Bluetooth-controlled, four-wheeled cars, each equipped with a buzzer laser and photosensor. The goal is simple but intense: eliminate opponents by targeting their photosensors with precision shots. As cars are disabled one by one, the last kart standing claims victory. "Smash Karts" is a thrilling testament to skill and strategy, offering an immersive experience that combines strategy, speed, and excitement.</p>
                            <input className="seeMore" type='checkbox' />
                        </div>
                    </div>
                </div>
                <div className="cardContainer">
                        <div className="layer" >
                        <div className="cardImg">
                            <img src={Drone} alt="Our Team" />
                        </div>
                        <div className="projectContent">
                            <h5>DRONE</h5>
                            <p className="projectText">The autonomously driven quartercopter drone with image recognition, featured at Flipkart Grid 2022, is a cutting-edge pick-and-place robot. This innovative robot is designed to identify and pick up items with the help of image recognition technology, revolutionizing logistics and automation in various industries.</p>
                            <input className="seeMore" type='checkbox' />
                        </div>
                    </div>
                </div>
                <div className="cardContainer">
                        <div className="layer" >
                        <div className="cardImg">
                            <img src={Roboticarm} alt="Our Team" />
                        </div>
                        <div className="projectContent">
                            <h5>ROBOTIC ARM</h5>
                            <p className="projectText">A meticulously designed joystick-controlled robotic arm that promises a seamless and precise user experience. Crafted with precision, this 3D-printed marvel is powered by servo motors, ensuring fluid and flawless movement in every operation.</p>
                            <input className="seeMore" type='checkbox' />
                        </div>
                    </div>
                </div>
                <div className="cardContainer">
                        <div className="layer" >
                        <div className="cardImg">
                            <img src={Shooter} alt="Our Team" />
                        </div>
                        <div className="projectContent">
                            <h5>BALL SHOOTER</h5>
                            <p className="projectText">The Tennis Ball Shooter is a versatile robot designed for manual control. Equipped with omni drive technology, it effortlessly collects and shoots tennis balls with precision. This innovative creation allows users to control the direction and speed of ball throws, adding excitement to the game. Get ready to enhance your tennis practice with the Tennis Ball Shooter's agility and accuracy!</p>
                            <input className="seeMore" type='checkbox' />
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
  )
}

export default Project

// export default class Project extends Component {
//     render() {
//       var settings = {
//         dots: true,
//         infinite: false,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         initialSlide: 0,
//         responsive: [
//           {
//             breakpoint: 1024,
//             settings: {
//               slidesToShow: 3,
//               slidesToScroll: 3,
//               infinite: true,
//               dots: true
//             }
//           },
//           {
//             breakpoint: 600,
//             settings: {
//               slidesToShow: 2,
//               slidesToScroll: 2,
//               initialSlide: 2
//             }
//           },
//           {
//             breakpoint: 480,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1
//             }
//           }
//         ]
//       };
//       return (
//         <div>
//           <h2> Responsive </h2>
//           <Slider {...settings}>
            // <div className="cardContainer">
            //   <div className="projectCard">
            //     <div className="cardImg">
            //       <img src={Sumobot} alt="" />
            //     </div>
            //     <div className="projectContent">
            //       <h3>This is content</h3>
            //       <p>DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
            //     </div>
            //   </div>    
            // </div>
            // <div className="cardContainer">
            //   <div className="projectCard">
            //     <div className="cardImg">
            //       <img src={Sumobot} alt="" />
            //     </div>
            //     <div className="projectContent">
            //       <h3>This is content</h3>
            //       <p>DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
            //     </div>
            //   </div>    
            // </div>
            // <div className="cardContainer">
            //   <div className="projectCard">
            //     <div className="cardImg">
            //       <img src={Sumobot} alt="" />
            //     </div>
            //     <div className="projectContent">
            //       <h3>This is content</h3>
            //       <p>DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
            //     </div>
            //   </div>    
            // </div>
            // <div className="cardContainer">
            //   <div className="projectCard">
            //     <div className="cardImg">
            //       <img src={Sumobot} alt="" />
            //     </div>
            //     <div className="projectContent">
            //       <h3>This is content</h3>
            //       <p>DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
            //     </div>
            //   </div>    
            // </div>
            // <div className="cardContainer">
            //   <div className="projectCard">
            //     <div className="cardImg">
            //       <img src={Sumobot} alt="" />
            //     </div>
            //     <div className="projectContent">
            //       <h3>This is content</h3>
            //       <p>DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
            //     </div>
            //   </div>    
            // </div>
//           </Slider>
//         </div>
//       );
//     }
//   }