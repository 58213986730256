import React, { useEffect } from 'react'
import styles from './Footer.module.css';
import { FaLinkedin,FaInstagram, FaTwitter } from 'react-icons/fa';
import { BiLogoLinkedin } from 'react-icons/bi';

const Modal = ({open, onClose}) => {
    
    if(!open) return null;
  return (
    <div onClick={onClose} className={styles.overlay}>
        <div className={styles.modalContainer}>
        <p onClick={onClose} className={styles.closeBtn}>X</p>
        
            <div className={styles.container}>
                <div className={styles.shape}>
                    <div className={styles.image1}></div>
                </div>
                <h3>Shwetank Aryan</h3>
                <h4 className={styles.title}>Web Developer</h4>
                <div className={styles.icons}>
                    <a href='https://instagram.com/aayan_lobo?igshid=NzZlODBkYWE4Ng==' target='blank'><FaInstagram /></a>
                    <a href='https://www.linkedin.com/in/shwetank-aryan' target='blank'><BiLogoLinkedin /></a>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.shape}>
                    <div className={styles.image2}></div>
                </div>
                <h3>Aayush Meshram</h3>
                <h4 className={styles.title}>Web Developer</h4>
                <div className={styles.icons}>
                    <a href='https://www.instagram.com/itzme_aayush05/' target='blank'><FaInstagram /></a>
                    <a href='https://www.linkedin.com/in/aayush-meshram-b70420258/' target='blank'><BiLogoLinkedin /></a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Modal