export const MenuItems = [
    {
        title : "Home",
        url : "/",
        cName : "navLinks"
    },
    {
        title : "About",
        url : "#about",
        cName : "navLinks"
    },
    {
        title : "Events",
        url : "#events",
        cName : "navLinks"
    },
    {
        title : "Projects",
        url : "#projects",
        cName : "navLinks"
    },
    {
        title : "Automax 2.0",
        url : "/automax",
        cName : "navLinks"
    },
    
    {
        title : "Contact",
        url : "#footer",
        cName : "navLinks"
    }
    // {
    //     title : "Register",
    //     url : "/register",
    //     cName : "navLinks"
    // },
]