import React, { Component } from 'react'
import styles from './Sponsor.module.css';
import Slider from 'react-slick';
import {motion} from 'framer-motion';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Img1 from '../../images/1.png';
import Img2 from '../../images/2.png';
import Img3 from '../../images/3.png';
import Img4 from '../../images/4.png';
import Img5 from '../../images/5.png';
import Img6 from '../../images/6.png';
import Img7 from '../../images/7.png';
import Img8 from '../../images/8.png';
import Img9 from '../../images/9.png';

class Sponsor extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 3000,
            cssEase: "linear"
          };
        return (
            <div className={styles.sponsor}>
                <Slider {...settings}>
                    <motion.div 
                    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}
                    className={styles.sponsorImg}>
                        <img src={Img1} alt='sponsor1' />
                    </motion.div>
                    <motion.div 
                    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}
                    className={styles.sponsorImg}>
                        <img src={Img2} alt='sponsor1' />
                    </motion.div>
                    <motion.div 
                    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}
                    className={styles.sponsorImg}>
                        <img src={Img3} alt='sponsor1' />
                    </motion.div>
                    <motion.div 
                    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}
                    className={styles.sponsorImg}>
                        <img src={Img4} alt='sponsor1' />
                    </motion.div>
                    <motion.div 
                    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}
                    className={styles.sponsorImg}>
                        <img src={Img5} alt='sponsor1' />
                    </motion.div>
                    <motion.div 
                    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}
                    className={styles.sponsorImg}>
                        <img src={Img6} alt='sponsor1' />
                    </motion.div>
                    <motion.div 
                    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}
                    className={styles.sponsorImg}>
                        <img src={Img7} alt='sponsor1' />
                    </motion.div>
                    <motion.div 
                    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}
                    className={styles.sponsorImg}>
                        <img src={Img8} alt='sponsor1' />
                    </motion.div>
                    <motion.div 
                    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}
                    className={styles.sponsorImg}>
                        <img src={Img9} alt='sponsor1' />
                    </motion.div>
                </Slider>
            </div>
        )
    }
}

export default Sponsor