import './Events.css';
import {motion} from 'framer-motion'
import Technosearch from '../../images/technosearch.png';
import { Link } from 'react-router-dom';
import Automax from "../../images/automax23.png";

const Upcoming = () => {
    return (
        <div className="cardContainer">
            <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0 },
            }}
            className='card'>
                <div className='imgBox'>
                    <img src={Automax} alt="" />
                </div>
                <div className='cardContent'>
                    <h3>Automax 2.0</h3>
                    <p>Experience the future of robotics at our 2-day workshop and competition. Learn, build, and compete with like-minded enthusiasts in this exciting event that combines innovation and teamwork.</p>
                    <Link to="/automax"><button>Check it out</button></Link>
                </div>
            </motion.div>
            <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0 },
            }}
            className='card'>
                <div className='imgBox'>
                    <img src={Technosearch} alt="" />
                </div>
                <div className='cardContent'>
                    <h3>Technosearch</h3>
                    <p>Get ready to experience a unique fusion of the past and the future and let the aura of "Retro Futurism" electrify your senses!
So, mark your calendars, for our largest annual technical fest, Technosearch 23’!</p>
                <a href='/technosearch23'><button>Check Now!</button></a>
                </div>
            </motion.div>

        </div>
    );
};

export default Upcoming;