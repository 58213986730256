import { v4 as uuidv4 } from "uuid";
import Card from "./Card";
import Carousel from "./Carousel";
import {motion} from 'framer-motion';
import styles from './App.module.css';
import Img1 from '../../images/gallery/1.jpeg';
import Img2 from '../../images/gallery/2.jpeg';
import Img3 from '../../images/gallery/3.jpeg';
import Img4 from '../../images/gallery/4.jpeg';
import Img5 from '../../images/gallery/5.jpeg';
import Img6 from '../../images/gallery/6.jpeg';
import Img7 from '../../images/gallery/7.jpeg';
import Img8 from '../../images/gallery/8.jpeg';
import Img9 from '../../images/gallery/9.jpeg';
import Img10 from '../../images/gallery/10.jpeg';
import Img11 from '../../images/gallery/11.jpeg';
import Img12 from '../../images/gallery/12.jpeg';
import Img13 from '../../images/gallery/13.jpeg';
import Img14 from '../../images/gallery/14.jpeg';
import Img15 from '../../images/gallery/15.jpeg';

import Img16 from '../../images/gallery/1.jpeg'

function App() {
  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img1} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img2} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img3} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img4} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img5} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img6} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img7} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img8} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img9} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img10} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img11} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img12} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img13} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img14} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={Img15} />
      )
    }
    
  ];

  return (
    <div className={styles.gallery}>
    <motion.div 
    initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
            }}
    className={styles.heading}>
      <h3>Our Gallery</h3>
      <p>Capturing moments in our gallery</p>
    </motion.div>
      <Carousel
        cards={cards}
        height="500px"
        width="50%"
        margin="6rem auto"
        offset={3}
        showArrows={true}
      />
    </div>
  );
}

export default App;
