import React from 'react';
import {motion} from "framer-motion";
import styles from './Achievements.module.css';
import Img1 from '../../images/robocon.jpeg';
import Img2 from '../../images/flipkartgrid.jpeg';

const Achievements = () => {
  return (
      <div className={styles.achievement}>
      <motion.div 
      initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
            }}
      className={styles.heading}>
        <h3>Achievements</h3>
        <p>Our journey of accomplishments</p>
      </motion.div>
        <div className={styles.card}>
            <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: -100 },
                visible: { opacity: 1, x: 0 },
            }}
            className={styles.layer} >
                <div className={styles.cardImg}>
                    <img src={Img1} alt="Our Team" />
                </div>
                <div className={styles.cardContent}>
                    <h5>ROBOCON</h5>
                    <p className={styles.text}>In the ideation round of Robocon 2023, our team scored an impressive 98.5 points out of 100. We presented a comprehensive project that involved CAD modeling and documentation, focusing on the development of ring throwing and collecting robots. This outstanding achievement reflects our team's dedication, creativity, and technical expertise, reaffirming our commitment to excellence in robotics. We continue to explore new horizons and opportunities to apply our innovative ideas in other exciting endeavors.</p>
                    <input className={styles.expandBtn} type='checkbox' />
                </div>
            </motion.div>
            <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5}}
            transition={{ duration: 0.5 }}
            variants={{
                hidden: { opacity: 0, x: 100 },
                visible: { opacity: 1, x: 0 },
            }}
            className={styles.layer}>
                <div className={styles.cardImg}>
                    <img src={Img2} alt="Our Team" />
                </div>
                <div className={styles.cardContent}>
                    <h5>Flipkart Grid 4.0 @Shaastra IITM</h5>
                    <p className={styles.text}>This project incorporates an autonomous drone, capable of performing pick and drop operations using camera vision. Operated by integrating a Pixhawk flight controller and Raspberry PI board, the model was presented at the Flipkart GRID 4.0 competition. It promises to push the boundaries of technology in logistics and healthcare sectors, meanwhile opening up another great area of innovation through its implementation.
Team : Shwetank Aryan, Aditya Kamat, Aryan Garg, Sandeep Kumar,Arushi Chandrakar, Abhishek K.Gautam, Aman Sharma</p>
                    <input className={styles.expandBtn} type='checkbox' />
                </div>
            </motion.div>
        </div>
      </div>
  )
}

export default Achievements;